import React, {useState} from "react"
import {useStaticQuery, graphql} from "gatsby";
import HallwayHeroesNavigation from "../../components/education/hallwayHeroesNavigation";
import InnerLayout from "../../components/global/innerLayout"
import PageTitle from "../../components/global/pageTitle";
import parse from "html-react-parser";
import Select from "../../components/global/select";
import "./educators-state-standards-alignments.scss"

const EducatorsTeacherGuidesPage = () => {

    const data = useStaticQuery(graphql`
    {
      craft {
        page: entry(section: "hallwayHeroesStateStandardsAlignments") {
          ... on Craft_hallwayHeroesStateStandardsAlignments_hallwayHeroesStateStandardsAlignments_Entry {
            seomatic {
              metaTitleContainer
              metaTagContainer
              metaLinkContainer
              metaScriptContainer
              metaJsonLdContainer
              metaSiteVarsContainer
              frontendTemplateContainer
            }
            id
            title: pageTitle
            uri
            section2ContentTitle
            contentDefaultHtmlEducation1
          }
        }
        files: assets(
          volume: "websiteEducationStateStandardsAlignmentsPdfs"
          orderBy: "title"
        ) {
          ... on Craft_websiteEducationStateStandardsAlignmentsPdfs_Asset {
            key: id
            title
            gradeOptionValue: grade
            gradeOptionLabel: grade(label: true)
            schoolTypeOptionValue: schoolType
            schoolTypeOptionLabel: schoolType(label: true)
            stateOptionValue: state
            stateOptionLabel: state(label: true)
            url
          }
        }
      }
    }
  `)

    let gradeOptions = false
    let stateOptions = false
    let schoolTypeOptions = false

    const [gradeFilter, setGradeFilter] = useState(false)
    const [stateFilter, setStateFilter] = useState(false)
    const [schoolTypeFilter, setSchoolTypeFilter] = useState(false)

    const getGradeOptions = () => {
        if (gradeOptions) {
            return gradeOptions
        }

        gradeOptions = data.craft.files
            .filter((e, i) => data.craft.files.findIndex(a => a["gradeOptionValue"] === e["gradeOptionValue"]) === i)
            .map(option => (
                {
                    title: option.gradeOptionLabel,
                    value: option.gradeOptionValue
                }
            ))
            .sort((a, b) => a.title.localeCompare(b.title))

        // Add all option
        gradeOptions.unshift({
            title: "All Grades",
            value: ""
        })

        return gradeOptions
    }

    const getStateOptions = () => {
        if (stateOptions) {
            return stateOptions
        }

        stateOptions = data.craft.files
            .filter((e, i) => data.craft.files.findIndex(a => a["stateOptionValue"] === e["stateOptionValue"]) === i)
            .map(option => (
                {
                    title: option.stateOptionLabel,
                    value: option.stateOptionValue
                }
            ))
            .sort((a, b) => a.title.localeCompare(b.title))

        // Add all option
        stateOptions.unshift({
            title: "All States",
            value: ""
        })

        return stateOptions
    }

    const getSchoolTypeOptions = () => {
        if (schoolTypeOptions) {
            return schoolTypeOptions
        }

        schoolTypeOptions = data.craft.files
            .filter((e, i) => data.craft.files.findIndex(a => a["schoolTypeOptionValue"] === e["schoolTypeOptionValue"]) === i)
            .map(option => (
                {
                    title: option.schoolTypeOptionLabel,
                    value: option.schoolTypeOptionValue
                }
            ))
            .sort((a, b) => a.title.localeCompare(b.title))

        // Add all option
        schoolTypeOptions.unshift({
            title: "All School Types",
            value: ""
        })

        return schoolTypeOptions
    }


    const changeGradeFilter = (event) => {
        setGradeFilter(event.target.value)
    }

    const changeStateFilter = (event) => {
        setStateFilter(event.target.value)
    }

    const changeSchoolTypeFilter = (event) => {
        setSchoolTypeFilter(event.target.value)
    }

    const renderFiles = () => {
        let files = data.craft.files

        if (gradeFilter) {
            files = files.filter(file => { return file.gradeOptionValue === gradeFilter })
        }

        if (stateFilter) {
            files = files.filter(file => { return file.stateOptionValue === stateFilter })
        }

        if (schoolTypeFilter) {
            files = files.filter(file => { return file.schoolTypeOptionValue === schoolTypeFilter })
        }

        let filesByState = []
        getStateOptions().map(state => {
            if (state.value) {
                filesByState.push({
                    state: state.title,
                    files: files.filter(file => { return file.stateOptionValue === state.value})
                })
            }
            return null
        })

        return filesByState.map(state => {
            if (state.files.length > 0) {
                return (
                    <>
                        <h4>{state.state}</h4>
                        <ul className="files">
                            {state.files.map(file => (
                                <li><a href={file.url} target="_blank" rel="noreferrer">{file.title}</a></li>
                            ))}
                        </ul>
                    </>
                )
            }
            return null
        })
    }

    return (
        <InnerLayout seomatic={data.craft.page.seomatic} entryId={data.craft.page.id}>
            <div className="midd education-hallway-heroes-state-standards-alignment-page">

                <PageTitle title={data.craft.page.title} className="hallway-heroes" />

                <div className="content-page">
                    <div className="container-small">

                        <div className="content-colum-row padding-top-none">
                            <div className="sidebar">
                                <HallwayHeroesNavigation active={data.craft.page.uri} />
                            </div>
                            <div className="content-right">
                                <h1>{data.craft.page.section2ContentTitle}</h1>
                                {parse(data.craft.page.contentDefaultHtmlEducation1)}

                                <label htmlFor="state-select">Select State:</label>
                                <Select id="state-select" name="state-select" changeHandler={changeStateFilter} options={getStateOptions()} />

                                <label htmlFor="school-type-select">Select School Type:</label>
                                <Select id="school-type-select" name="school-type-select" changeHandler={changeSchoolTypeFilter} options={getSchoolTypeOptions()} />

                                <label htmlFor="grade-select">Select a Grade:</label>
                                <Select id="grade-select" name="grade-select" changeHandler={changeGradeFilter} options={getGradeOptions()} />

                                {renderFiles()}

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </InnerLayout>
    )
}

export default EducatorsTeacherGuidesPage
